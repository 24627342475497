<template>
  <v-row class="px-4">
    <header-form />
    <v-col
      v-if="isAdminLogin()"
      md="3"
      sm="12"
      cols
    >
      <v-select
        v-model="params.groupId"
        :items="optionGroups"
        :label="$t('user.userList.filter.selectGroup')"
        item-text="title"
        item-value="id"
        dense
        outlined
        clearable
      />
    </v-col>
    <v-col
      md="3"
      sm="12"
      cols
    >
      <v-text-field
        v-model="params.email"
        label="E-mail"
        outlined
        dense
        clearable
      />
    </v-col>
    <v-col
      v-if="isAdminLogin() || isGroupAdminLogin()"
      md="3"
      sm="12"
      cols
    >
      <v-select
        v-model="params.role"
        :items="dataRoleAll"
        :label="$t('user.userList.filter.selectRoles')"
        item-text="text"
        item-value="value"
        dense
        outlined
        clearable
      />
    </v-col>
    <v-col
      v-if="isAdminLogin()"
      md="3"
      sm="12"
      cols
    >
      <v-select
        v-model="params.block"
        :items="items"
        :label="$t('user.userList.filter.selectStatus')"
        item-text="text"
        item-value="value"
        dense
        outlined
        clearable
      />
    </v-col>
  </v-row>
</template>

<script>
  // Vuex
  import { sync, get } from 'vuex-pathify'
  import HeaderForm from './HeaderForm.vue'
  import _ from 'lodash';
  export default {
    name: 'FilterForm',
    components: {
      'header-form': HeaderForm,
    },
    data: () => ({
      status: '',
      items: [
        { text: 'Hoạt động', value: 'false' },
        { text: 'Bị khóa', value: 'true' },
      ],
      optionGroups: [],
    }),
    computed: {
      ...sync('adminUser', [
        'params',
      ]),
      ...get('adminRole', [
        'dataRoleAll',
      ]),
      ...get('adminGroup', [
        'dataItems',
        'totalItem',
      ]),
    },
    methods: {
      checkSearchStr: _.debounce(function() {
        this.$store.dispatch('adminUser/fetchDataUserList', this.getTokenLogin())
    }, 1000),
    },
    watch: {
      'params.email': {
        handler (newVal, oldVal) {
          /* when search email then limit change => offset change
            if offset !== 1 is already call API return offset = 1
            else offset == 1 not call API should call API */
          if (this.params.offset !== 1) {
            this.params.offset = 1
          } else {
            this.checkSearchStr()
          }
        },
        deep: true,
      },
      'params.appId': {
        handler (newVal, oldVal) {
          if (this.params.offset !== 1) {
            this.params.offset = 1
          } else {
            this.$store.dispatch('adminUser/fetchDataUserList', this.getTokenLogin())
          }
        },
        deep: true,
      },
      'params.role': {
        handler (newVal, oldVal) {
          /* when search role then limit change => offset change
            if offset !== 1 is already call API return offset = 1
            else offset == 1 not call API should call API */
          if (this.params.offset !== 1) {
            this.params.offset = 1
          } else {
            this.$store.dispatch('adminUser/fetchDataUserList', this.getTokenLogin())
          }
        },
        deep: true,
      },
      'params.block': {
        handler (newVal, oldVal) {
          /* when search isBlock then limit change => offset change
            if offset !== 1 is already call API return offset = 1
            else offset == 1 not call API should call API */
          if (this.params.offset !== 1) {
            this.params.offset = 1
          } else {
            this.$store.dispatch('adminUser/fetchDataUserList', this.getTokenLogin())
          }
        },
        deep: true,
      },
      'params.groupId': {
        handler (newVal, oldVal) {
          /* when search role then limit change => offset change
            if offset !== 1 is already call API return offset = 1
            else offset == 1 not call API should call API */
          if (this.params.offset !== 1) {
            this.params.offset = 1
          } else {
            this.$store.dispatch('adminUser/fetchDataUserList', this.getTokenLogin())
          }
        },
        deep: true,
      },
    },
    async created () {
      // call API fetchDataRoleAll from store adminRole
      this.$store.dispatch('adminRole/fetchDataRoleAll', {
        tokenLogin: this.getTokenLogin(),
        isAdminLogin: this.isAdminLogin(),
      })
      const dataGroups = await this.$store.dispatch('adminGroup/getAllDataGroups', this.getTokenLogin())
      this.optionGroups = dataGroups;
    },
  }
</script>

<style scoped>
</style>
