<template>
  <v-dialog
    v-if="isShowDialogUserFormEdit"
    v-model="isShowDialogUserFormEdit"
    persistent
    max-width="500"
  >
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
    >
      <v-card>
        <v-card-title>
          <span
            class="headline"
            v-text="$t('user.userForm.breadcrumb.titleUserUpdate')"
          />
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="dataForm.email"
            :label="$t('user.userForm.form.email')"
            outlined
            dense
            disabled
          />
          <v-text-field
            v-model="dataForm.fullName"
            :rules="nameRules"
            :label="$t('user.userForm.form.name')"
            outlined
            dense
          />
          <v-select
            v-model="dataForm.role"
            :items="dataRoleAll"
            item-text="text"
            item-value="value"
            :label="$t('user.userForm.form.roles')"
            outlined
            dense
          />
          <v-select
            v-model="dataForm.block"
            :items="items"
            item-text="text"
            item-value="value"
            :label="$t('user.userForm.form.status')"
            outlined
            dense
          />
          <v-autocomplete
            v-model="valuesApp"
            :items="itemsApp"
            item-text="title"
            item-value="id"
            label="Create App"
            dense
            multiple
            hide-selected
            outlined
            persistent-hint
            small-chips
            clearable
            deletable-chips
          />
          <v-autocomplete
            v-model="valuesApp"
            :items="itemsApp"
            item-text="title"
            item-value="id"
            label="Admin App"
            dense
            multiple
            hide-selected
            outlined
            persistent-hint
            small-chips
            clearable
            deletable-chips
          />
          <v-autocomplete
            v-model="valuesApp"
            :items="itemsApp"
            item-text="title"
            item-value="id"
            label="Manager App"
            dense
            multiple
            hide-selected
            outlined
            persistent-hint
            small-chips
            clearable
            deletable-chips
          />
          <v-autocomplete
            v-model="valuesApp"
            :items="itemsApp"
            item-text="title"
            item-value="id"
            label="User App"
            dense
            multiple
            hide-selected
            outlined
            persistent-hint
            small-chips
            clearable
            deletable-chips
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="blue-grey"
            @click="isShowDialogUserFormEdit = false"
            v-text="$t('user.userForm.actions.close')"
          />
          <v-btn
            color="info"
            :disabled="!valid"
            @click="clickUpdateUser"
            v-text="$t('user.userForm.actions.save')"
          />
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
  // Vuex
  import { sync, get } from 'vuex-pathify'
  export default {
    name: 'DialogRoleFormEdit',
    data: () => ({
      // check error form fullName
      valid: true,
      nameRules: [
        (v) => !!v || 'Name is required',
        (v) => (v && v.length <= 255) || 'Max 255 characters',
      ],
      items: [
        { text: 'Hoạt động', value: false },
        { text: 'Bị khóa', value: true },
      ],
      itemsApp: ['App test_1', 'App test_2', 'App test_3'],
      valuesApp: ['App test_1', 'App test_2'],
    }),
    computed: {
      ...sync('adminUser', [
        'isShowDialogUserFormEdit',
        'dataForm',
      ]),
      ...get('adminRole', [
        'dataRoleAll',
      ]),
    },
    methods: {
      async clickUpdateUser () {
        const valueCheck = this.$refs.form.validate()
        // if valueCheck = false then stop function clickUpdateUser
        if (!valueCheck) {
          return
        }
        // if valueCheck = true then call API
        // call API update user from store adminUser
        const dataAdminUserResult = await this.$store.dispatch('adminUser/updateUser', this.getTokenLogin())
        if (dataAdminUserResult.status === false) {
          this.$toast.error(dataAdminUserResult.data.message)
          return
        }
        this.$toast.success(this.$t('user.userForm.notifications.userEdit.success'))
        setTimeout(() => {
          this.isShowDialogUserFormEdit = false
          // call API get data users list
          this.$store.dispatch('adminUser/fetchDataUserList', this.getTokenLogin())
        }, 1000)
      },
    },
  }
</script>

<style scoped>
</style>
