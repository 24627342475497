<template>
  <v-dialog
    v-model="isShowDialogUserFormDelete"
    max-width="400"
  >
    <v-card>
      <v-toolbar
        color="grey lighten-3"
        dense
        flat
      >
        <v-toolbar-title class="text-body-2 font-weight-bold grey--text">
          {{ $t('user.userForm.breadcrumb.titleUserConfirm') }}
        </v-toolbar-title>
        <v-spacer />
        <v-btn
          icon
          @click="isShowDialogUserFormDelete = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="pa-4 black--text">
        {{ $t('user.userForm.form.message') }}
      </v-card-text>
      <v-card-actions class="pt-3">
        <v-spacer />
        <v-btn
          color="grey"
          text
          class="body-2 font-weight-bold"
          @click="clickBlockUser"
        >
          {{ this.dataForm.block ? $t('user.userForm.actions.notBlock') : $t('user.userForm.actions.block') }}
        </v-btn>
        <v-btn
          color="primary"
          class="body-2 font-weight-bold"
          outlined
          @click="clickDeleteUser"
          v-text="$t('user.userForm.actions.delete')"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  // Vuex
  import { sync, get } from 'vuex-pathify'
  export default {
    name: 'DialogUserFormDelete',
    computed: {
      ...sync('adminUser', [
        'isShowDialogUserFormDelete',
      ]),
      ...get('adminUser', [
        'dataForm',
      ]),
    },
    methods: {
      async clickBlockUser () {
        // call API blockUser from Store adminUser
        const dataAdminUserResult = await this.$store.dispatch('adminUser/blockUser', this.getTokenLogin())
        if (dataAdminUserResult.status === false) {
          this.$toast.error(dataAdminUserResult.data.message)
          return
        } else if (dataAdminUserResult.data.block === true) {
          this.$toast.success(this.$t('user.userForm.notifications.userDelete.lock'))
        } else {
          this.$toast.success(this.$t('user.userForm.notifications.userDelete.unlock'))
        }
        setTimeout(() => {
          this.isShowDialogUserFormDelete = false
          // call API get data users list from Store adminUser
          this.$store.dispatch('adminUser/fetchDataUserList', this.getTokenLogin())
        }, 800)
      },
      //
      async clickDeleteUser () {
        // call API deleteUser from Store adminUser
        const dataAdminUserResult = await this.$store.dispatch('adminUser/deleteUser', this.getTokenLogin())
        if (dataAdminUserResult.status === false) {
          this.$toast.error(dataAdminUserResult.data.message)
          return
        }
        this.$toast.success(this.$t('user.userForm.notifications.userDelete.success'))
        setTimeout(() => {
          this.isShowDialogUserFormDelete = false
          // call API get data users list from Store adminUser
          this.$store.dispatch('adminUser/fetchDataUserList', this.getTokenLogin())
        }, 700)
      },
    },
  }
</script>

<style scoped>
</style>
