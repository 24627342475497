<template>
  <v-container
    id="admin-user-view"
    fluid
    tag="section"
  >
    <material-card
      color="primary"
      icon="mdi-account-outline"
    >
      <template #title>
        {{ $t('user.userList.breadcrumb.title') }}
      </template>
      <filter-form />
      <table-user />
    </material-card>
    <dialog-user-form-add />
    <dialog-user-form-detail />
    <dialog-user-form-edit />
    <dialog-user-form-delete />
  </v-container>
</template>

<script>
  import FilterForm from '../modules/admin/user/components/FilterForm'
  import TableUser from '../modules/admin/user/components/Table'
  import DialogUserFormAdd from '../modules/admin/user/components/DialogUserFormAdd'
  import DialogUserFormDetail from '../modules/admin/user/components/DialogUserFormDetail'
  import DialogUserFormEdit from '../modules/admin/user/components/DialogUserFormEdit'
  import DialogUserFormDelete from '../modules/admin/user/components/DialogUserFormDelete'
  export default {
    name: 'AdminUser',
    components: {
      FilterForm,
      TableUser,
      DialogUserFormAdd,
      DialogUserFormDetail,
      DialogUserFormEdit,
      DialogUserFormDelete,
    },
    // Title page
    metaInfo: {
      titleTemplate: 'Admin User',
    },
  }
</script>
