<template>
  <div>
    <v-simple-table>
      <thead>
        <tr>
          <th
            class="primary--text font-weight-bold"
            v-text="$t('user.userList.table.email')"
          />
          <th
            class="primary--text font-weight-bold"
            v-text="$t('user.userList.table.name')"
          />
          <th
            class="primary--text font-weight-bold"
            v-text="$t('user.userList.table.roles')"
          />
          <th
            class="primary--text font-weight-bold"
            v-text="$t('user.userList.table.status')"
          />
          <th
            class="primary--text font-weight-bold text-center"
            v-text="$t('user.userList.table.actions')"
          />
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(data, index) in dataUsers"
          :key="index+'_dataUsers'"
        >
          <td>
            {{ data.email }}
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon>
                  <v-icon
                    color="grey lighten-1"
                    v-bind="attrs"
                    size="18"
                    v-on="on"
                    @click="copyText(data.id)"
                    v-text="'mdi-content-copy'"
                  />
                </v-btn>
              </template>
              <span v-text="data.id" />
            </v-tooltip>
          </td>
          <td v-text="data.fullName" />
          <td v-text="data.role" />
          <td v-text="getUserStatus(data)" />
          <td class="text-center">
            <v-menu offset-y>
              <template v-slot:activator="{ attrs, on }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-dots-horizontal</v-icon>
                </v-btn>
              </template>
              <v-list v-if="isAdminLogin()">
                <v-list-item @click="() => openFormDetailUser(data.id)">
                  <v-list-item-title v-text="$t('user.userList.actions.view')" />
                </v-list-item>
                <v-divider />
                <v-list-item @click="() => openFormEditUser(data.id)">
                  <v-list-item-title v-text="$t('user.userList.actions.edit')" />
                </v-list-item>
                <v-divider />
                <v-list-item @click="() => clickDeleteUser(data.id, data.block)">
                  <v-list-item-title v-text="$t('user.userList.actions.delete')" />
                </v-list-item>
                <v-divider />
                <v-list-item @click="() => changeUserStatus(data)">
                  <v-list-item-title v-text="getActionStatus(data)" />
                </v-list-item>
              </v-list>
              <v-list v-else>
                <v-list-item @click="() => changeUserStatus(data)">
                  <v-list-item-title v-text="getActionStatus(data)" />
                </v-list-item>
              </v-list>
            </v-menu>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <v-row class="ml-2 mt-4">
      <v-col cols="4">
        <v-pagination
          v-model="params.offset"
          :length="getPageTotal(totalUser, params.limit)"
        />
      </v-col>
      <v-col cols="4">
        <v-select
          v-model="params.limit"
          :items="limitOptions"
          label="Rows per page"
          dense
          solo
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
  // Vuex
  import { get, sync } from 'vuex-pathify'
  import { USER_STATUS, USER_CHANGE_STATUS } from '../constant'
  export default {
    name: 'Table',
    computed: {
      // From Stores
      ...get('adminRole', [
        'dataRoleAll',
      ]),
      ...get('adminUser', [
        'totalUser',
        'dataUsers',
      ]),
      ...get('layout', [
        'limitOptions',
      ]),
      ...sync('adminUser', [
        'params',
        'isShowDialogUserFormDetail',
        'isShowDialogUserFormEdit',
        'isShowDialogUserFormDelete',
        'dataForm',
      ]),
    },
    watch: {
      'params.offset': {
        handler (newVal, oldVal) {
          // when change offset then call api
          this.$store.dispatch('adminUser/fetchDataUserList', this.getTokenLogin())
        },
        deep: true,
      },
      'params.limit': {
        handler (newVal, oldVal) {
          // when change limit then call api and offset start = 1
          if (this.params.offset !== 1) {
            this.params.offset = 1
          } else {
            this.$store.dispatch('adminUser/fetchDataUserList', this.getTokenLogin())
          }
        },
        deep: true,
      },
    },
    async created () {
      // call API fetchDataUserList from Store
      await this.$store.dispatch('adminUser/fetchDataUserList', this.getTokenLogin())
    },
    methods: {
      getActionStatus (dataUser) {
        const { banned, verifyAccount, wattingUser } = dataUser
        if (!verifyAccount || verifyAccount === USER_STATUS.inactive) {
          return 'Kích hoạt tài khoản'
        }
        if (banned) {
          return 'Mở khoá tài khoản trong nhóm'
        }
        if (wattingUser) {
          return 'Chấp nhận tài khoản vô nhóm'
        }
        return 'Khoá tài khoản ở trong nhóm'
      },
      getUserStatus (dataUser) {
        const { banned, block, verifyAccount, wattingUser } = dataUser
        if (block === USER_STATUS.active) {
          return 'Tài khoản đã bị khóa'
        }
        if (!verifyAccount || verifyAccount === USER_STATUS.inactive) {
          return 'Tài khoản chưa kích hoạt'
        }
        if (wattingUser) {
          return 'Tài khoản đang chờ duyệt vô nhóm'
        }
        if (banned) {
          return 'Tài khoản đã bị khoá trong nhóm'
        }
        return 'Hoạt Động'
      },
      async changeUserStatus(dataUser) {
        const groupId = localStorage.getItem('groupId')
        let changeStatus = USER_CHANGE_STATUS.BAN_GROUP_ACCOUNT;
        const { banned, verifyAccount, wattingUser, id } = dataUser
        if (!verifyAccount || verifyAccount === USER_STATUS.inactive) {
          changeStatus = USER_CHANGE_STATUS.VERIFY_ACCOUNT
        } else if (banned) {
          changeStatus = USER_CHANGE_STATUS.UNBAN_GROUP_ACCOUNT
        } else if (wattingUser) {
          changeStatus = USER_CHANGE_STATUS.ALLOW_GROUP_ACCOUNT
        }
        const resultAPI = await this.$store.dispatch('adminUser/changeUserStatus', {
          tokenLogin: this.getTokenLogin(),
          dataForm: {
            groupId,
            changeStatus,
            userId: id,
          },
        })
        if (!resultAPI?.status) {
          this.$toast.error(resultAPI?.data?.message || 'There is a problem with the system, please try again later')
          return
        }
        this.showProgressCircular()
        setTimeout(() => {
          this.$store.dispatch('adminUser/fetchDataUserList', this.getTokenLogin())
          this.hideProgressCircular()
          this.$toast.success(resultAPI?.data?.message)
        }, 1000);
      },
      async openFormDetailUser (id) {
        this.dataForm.id = id
        // call API detailUser from Store adminUser
        await this.$store.dispatch('adminUser/detailUser', this.getTokenLogin())
        this.isShowDialogUserFormDetail = true
      },
      async openFormEditUser (id) {
        // get id from function openFormEditUser transmisson in dataForm of Store
        this.dataForm.id = id
        // call API from Store adminUser
        await this.$store.dispatch('adminUser/detailUser', this.getTokenLogin())
        this.isShowDialogUserFormEdit = true
      },
      // function Delete user
      async clickDeleteUser (id, block) {
        // get id from function clickDeleteUser transmisson in dataForm of Store
        this.dataForm.id = id
        this.dataForm.block = block
        this.isShowDialogUserFormDelete = true
      },
      async copyText (textToCopy) {
        if (navigator.clipboard && window.isSecureContext) {
          await navigator.clipboard.writeText(textToCopy)
        } else {
          const textArea = document.createElement('textarea');
          textArea.value = textToCopy;
          // Move textarea out of the viewport so it's not visible
          textArea.style.position = 'absolute'
          textArea.style.left = '-999999px'
          document.body.prepend(textArea)
          textArea.select()
          try {
            document.execCommand('copy')
          } catch (error) {
            console.error(error)
          } finally {
            textArea.remove()
          }
        }
      },
    },
  }
</script>

<style scoped>
</style>
