<template>
  <v-col
    v-if="isAdminLogin() || isGroupAdminLogin()"
    cols="12"
    class="text-end"
  >
    <v-btn
      color="success"
      @click="clickAddUser"
      v-text="$t('user.userList.actions.add')"
    />
  </v-col>
</template>

<script>
  // Vuex
  import { sync } from 'vuex-pathify'
  export default {
    name: 'HeaderForm',
    computed: {
      ...sync('adminUser', [
        'isShowDialogUserForm',
      ]),
    },
    methods: {
      clickAddUser () {
        this.isShowDialogUserForm = true
        this.$store.dispatch('adminUser/setDefaultFormDataUser')
      },
    },
  }
</script>

<style scoped>

</style>
