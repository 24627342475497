<template>
  <v-dialog
    v-if="isShowDialogUserForm"
    v-model="isShowDialogUserForm"
    persistent
    max-width="500"
  >
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
    >
      <v-card>
        <v-card-title>
          <span
            class="headline"
            v-text="$t('user.userForm.breadcrumb.titleUserAdd')"
          />
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="dataForm.fullName"
            :rules="nameRules"
            :label="$t('user.userForm.form.name')"
            outlined
            dense
          />
          <v-text-field
            v-model="dataForm.email"
            :rules="emailRules"
            :label="$t('user.userForm.form.email')"
            outlined
            dense
          />
          <v-text-field
            v-model="dataForm.password"
            outlined
            dense
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[passwordRules.required, passwordRules.min, passwordRules.max]"
            :type="showPassword ? 'text' : 'password'"
            :label="$t('user.userForm.form.password')"
            @click:append="showPassword = !showPassword"
          />
          <v-text-field
            v-model="dataForm.passwordConfirm"
            outlined
            dense
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[passwordRules.required, confirmPassword]"
            :type="showPassword ? 'text' : 'password'"
            :label="$t('user.userForm.form.confirmPassword')"
            @click:append="showPassword = !showPassword"
          />
          <v-select
            v-model="dataForm.role"
            :items="dataRoleAll"
            item-text="text"
            item-value="value"
            :rules="roleRules"
            :label="$t('user.userForm.form.roles')"
            outlined
            dense
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="blue-grey"
            @click="isShowDialogUserForm = false"
            v-text="$t('user.userForm.actions.close')"
          />
          <v-btn
            color="info"
            :disabled="!valid"
            @click="save"
            v-text="$t('user.userForm.actions.save')"
          />
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
  // Vuex
  import { sync, get } from 'vuex-pathify'
  export default {
    name: 'DialogUserFormAdd',
    data: () => ({
      // error form email and password
      valid: true,
      nameRules: [
        (v) => !!v || 'Name is required',
        (v) => (v && v.length <= 255) || 'Max 255 characters',
      ],
      emailRules: [
        (v) => !!v || 'E-mail is required',
        (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
        (v) => (v && v.length <= 255) || 'Max 255 characters',
      ],
      showPassword: false,
      passwordRules: {
        required: (v) => !!v || 'Password is required',
        min: (v) => (v && v.length >= 8) || 'Min 8 characters',
        max: (v) => (v && v.length <= 255) || 'Max 255 characters',
      },
      roleRules: [
        (v) => !!v || 'Role is required',
      ],
      // end form email and password
    }),
    computed: {
      confirmPassword () {
        return () => this.dataForm.password === this.dataForm.passwordConfirm || 'The password is not correct'
      },
      ...sync('adminUser', [
        'isShowDialogUserForm',
        'dataForm',
        'params',
      ]),
      ...get('adminRole', [
        'dataRoleAll',
      ]),
    },
    methods: {
      async save () {
        const valueCheck = this.$refs.form.validate()
        // if valueCheck = false then stop function save
        if (!valueCheck) {
          return
        }
        const dataAdminUserResult = await this.$store.dispatch('adminUser/createUser', this.getTokenLogin())
        if (dataAdminUserResult.status === false) {
          if (dataAdminUserResult.data.length > 0) {
            dataAdminUserResult.data.forEach(element => {
              const keyObject = Object.keys(element)
              const messageError = `user.userForm.notifications.error['${element[keyObject].code}']`
            this.$toast.error(keyObject + ': ' + this.$t(messageError))
              // this.$toast.error(keyObject + ': ' + element[keyObject].message)
          });
          } else {
            this.$toast.error(dataAdminUserResult.data.message)
          }
          return
        }
        this.$toast.success(this.$t('user.userForm.notifications.userAdd.success'))
        setTimeout(() => {
          this.isShowDialogUserForm = false
          if (this.params.offset !== 1) {
            this.params.offset = 1
          } else {
            this.$store.dispatch('adminUser/fetchDataUserList', this.getTokenLogin())
          }
        }, 1000)
      },
    },
  }
</script>

<style scoped>
</style>
