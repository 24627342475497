export const USER_STATUS = {
    active: true,
    inactive: false,
}

export const USER_CHANGE_STATUS = {
    UNBAN_GROUP_ACCOUNT: 'unban-group-account',
    BAN_GROUP_ACCOUNT: 'ban-group-account',
    ALLOW_GROUP_ACCOUNT: 'allow-group-account',
    VERIFY_ACCOUNT: 'verify-account',
}
