<template>
  <v-dialog
    v-model="isShowDialogUserFormDetail"
    max-width="500"
  >
    <v-form>
      <v-card>
        <v-card-title>
          <span
            class="headline"
            v-text="$t('user.userForm.breadcrumb.titleUserDetail')"
          />
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="dataForm.email"
            :label="$t('user.userForm.form.email')"
            outlined
            dense
            readonly
          />
          <v-text-field
            v-model="dataForm.fullName"
            :label="$t('user.userForm.form.name')"
            outlined
            dense
            readonly
          />
          <v-select
            v-model="dataForm.role"
            :items="dataRoleAll"
            item-text="text"
            item-value="value"
            :label="$t('user.userForm.form.roles')"
            outlined
            dense
            readonly
          />
          <v-select
            v-model="dataForm.block"
            :items="items"
            item-text="text"
            item-value="value"
            :label="$t('user.userForm.form.status')"
            outlined
            dense
            readonly
          />
          <v-autocomplete
            v-model="valuesApp"
            :items="itemsApp"
            item-text="title"
            item-value="id"
            label="Create App"
            dense
            multiple
            outlined
            small-chips
            readonly
          />
          <v-autocomplete
            v-model="valuesApp"
            :items="itemsApp"
            item-text="title"
            item-value="id"
            label="Admin App"
            dense
            multiple
            outlined
            small-chips
            readonly
          />
          <v-autocomplete
            v-model="valuesApp"
            :items="itemsApp"
            item-text="title"
            item-value="id"
            label="Manager App"
            dense
            multiple
            outlined
            small-chips
            readonly
          />
          <v-autocomplete
            v-model="valuesApp"
            :items="itemsApp"
            item-text="title"
            item-value="id"
            label="User App"
            dense
            multiple
            outlined
            small-chips
            readonly
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="blue-grey"
            @click="isShowDialogUserFormDetail = false"
            v-text="$t('user.userForm.actions.close')"
          />
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
  // Vuex
  import { sync, get } from 'vuex-pathify'
  export default {
    name: 'DialogUserFormDetail',
    data: () => ({
      items: [
        { text: 'Hoạt động', value: false },
        { text: 'Bị khóa', value: true },
      ],
      itemsApp: ['App test_1', 'App test_2', 'App test_3'],
      valuesApp: ['App test_1', 'App test_2'],
    }),
    computed: {
      ...sync('adminUser', [
        'isShowDialogUserFormDetail',
        'dataForm',
      ]),
      ...get('adminRole', [
        'dataRoleAll',
      ]),
    },
  }
</script>

<style scoped>
</style>
